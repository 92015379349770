// extracted by mini-css-extract-plugin
export var column = "Thanks__column__nQYsB";
export var container = "Thanks__container__jgG0g";
export var helpContainer = "Thanks__helpContainer__tdo3b";
export var row = "Thanks__row__EgNh1";
export var serviceBlock = "Thanks__serviceBlock__wW2Fh";
export var serviceText = "Thanks__serviceText__R2fTR";
export var svgPathDirectory = "Thanks__svgPathDirectory__xfoTY";
export var svgPathMarketplace = "Thanks__svgPathMarketplace__kCIfY";
export var svgPathRealA = "Thanks__svgPathRealA__Eyi5_";
export var svgPathRealB = "Thanks__svgPathRealB__YpSU7";
export var svgPathResources = "Thanks__svgPathResources__aZb8o";
export var thanks = "Thanks__thanks__sawVF";
export var thanksContainer = "Thanks__thanksContainer__RxRJe";
export var thanksIntro = "Thanks__thanksIntro__uPBLD";